@media screen and (max-width: 1450px) {
	.interactive_map_Container {
	    .map_container {
	        .map_overlay {
	            svg {
	            	height: calc(100vh - 75px) !important;
	            }
	        }
	    }
	}
}

@media screen and (max-width: 1600px) {
	.interactive_map_Container {
	    .map_container {
	        .map_overlay {
	        	max-height: none !important;

	            svg {
	            	height: unset !important;
	            }
	        }
	    }
	}
}